<template>
  <section class="container" style="background:#fff;">
    <stockTopMenu
      curPosition="店铺排班"
      windowId="deptArrange"
      module="scheduling"
    ></stockTopMenu>
    <div v-loading.fullscreen.lock="fullscreenLoading" style="overflow-y: auto;height: 94vh;">
      <div style="padding: 10px 20px 100px">
        <div class="class-explain">
          <div style="display: flex; width: 70%">
            <span style="width: 17%">班次说明</span>
            <div class="class-main">
              <template v-for="(item, index) in classInfoList">
                <div
                  class="classTag"
                  :style="{ background: item.bgColor }"
                  :key="index"
                  v-if="item.name"
                >
                  <span v-if="item.name">{{ item.name }}：</span>
                  <span style="margin-right: 5px" v-if="item.firstTime">{{
                    item.firstTime
                  }}</span>
                  <span style="margin-right: 5px" v-if="item.secondTime">{{
                    item.secondTime
                  }}</span>
                  <span v-if="item.thirdTime">{{ item.thirdTime }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="class-search"></div>
        </div>
        <div style="margin: 10px 0 30px">
          <span>排班周期</span>
          <span style="margin: 0 10px" v-if="tableData.length == '0'"
            >未设置</span
          >
          <span
            style="color: #1890ff; margin-left: 10px"
            @click="weekVisible = true"
            >添加</span
          >
          <el-table
            v-if="tableData.length != '0'"
            border
            style="width: 100%"
            :loading="loading"
            :data="tableData"
            :header-cell-style="{
              padding: '10px 0',
              background: '#f5f5f5',
              color: '#333',
            }"
            :cell-style="{ padding: '10px 0' }"
          >
            <el-table-column prop="weekId" label="周期编号" align="center">
            </el-table-column>
            <el-table-column prop="weekName" label="周期名称" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  slot="reference"
                  type="text"
                  icon="el-icon-delete"
                  style="color: #f56c6c; padding: 0"
                  @click="del(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="operat">
          <div style="display: flex; align-items: center">
            <el-date-picker
              v-model="month"
              type="month"
              value-format="yyyyMM"
              placeholder="选择月"
              :clearable="false"
            >
            </el-date-picker>
            <el-select
              v-model="search"
              filterable
              placeholder="请选择人员"
              style="margin-left: 20px"
              :clearable="true"
            >
              <el-option
                v-for="item in perslonList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              icon="el-icon-search"
              style="margin: 0 10px"
              type="primary"
              @click="fetch()"
              >查询</el-button
            >
            <!-- <div style="display: flex; align-items: center">
              <span style="margin: 0 10px">未排班时</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">员工可打卡</el-checkbox>
                <el-checkbox label="2">员工可以选择班次打卡</el-checkbox>
              </el-checkbox-group>
            </div> -->
          </div>
        </div>
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
      </div>
      <el-dialog
        title="人员排班"
        :visible.sync="dialogVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div>
          <el-form ref="form" :model="form" label-width="auto">
            <el-form-item label="排班模式" prop="submitType">
              <el-radio-group v-model="form.submitType" style="width: 100%">
                <el-radio-button label="1" :disabled="!form.dayEvent"
                  >一键排班</el-radio-button
                >
                <el-radio-button label="2">按天排班</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="考勤人员" prop="perslon">
              <el-select
                v-model="form.perslon"
                filterable
                placeholder="请选择考勤人员"
                style="width: 100%"
                :disabled="!form.dayEvent"
              >
                <el-option
                  v-for="item in perslonList"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="排班班次"
              prop="classId"
              v-if="form.submitType != '1'"
            >
              <el-select
                v-model="form.classId"
                filterable
                placeholder="请选择排班班次"
                style="width: 100%"
              >
                <el-option
                  v-for="item in classInfoList"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="周期"
              prop="weekId"
              v-if="form.submitType == '1'"
            >
              <el-select
                v-model="form.weekId"
                filterable
                placeholder="请选择排班周期"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tableData"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="请选择排班日期"
              prop="dateList"
              v-if="form.submitType != '1'"
            >
              <el-date-picker
                v-model="form.dateList"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="请选择开始时间"
              prop="dateList"
              v-if="form.submitType == '1'"
            >
              <el-date-picker
                v-model="form.oneTime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="claer">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 设置周期 -->
      <el-dialog
        title="设置周期规则"
        :visible.sync="weekVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          :model="weekForm"
          ref="weekForm"
          label-width="100px"
          :rules="weekRules"
        >
          <el-form-item prop="name" label="周期名称">
            <el-input
              v-model="weekForm.name"
              placeholder="请输入周期名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="weekDays" label="周期天数">
            <el-input
              v-model="weekForm.weekDays"
              placeholder="请输入周期天数"
              clearable
              type="number"
              min="2"
              max="31"
              @input="newWeekData"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in weekData"
            :key="index"
            :label="'第' + item.id + '天'"
          >
            <el-select
              v-model="item.weekId"
              filterable
              placeholder="请选择排班班次"
              style="width: 100%"
            >
              <el-option
                v-for="item in classInfoList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" @click="submitForm('weekForm')"
            >提交</el-button
          >
        </el-form>
      </el-dialog>
    </div>
  </section>
</template>

<style scoped>
.class-explain {
  display: flex;
  justify-content: space-between;
}
.class-main {
  display: flex;
  flex-wrap: wrap;
  padding: -5px 10px;
  margin-left: 10px;
}
.classTag {
  margin-top: 5px;
  padding: 0 7px;
  background: #5e90ff;
  color: #ffffff;
  height: 24px;
  line-height: 22px;
  border-radius: 6px;
  margin-right: 15px;
}
.operat {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
</style>
<script>
import moment from "moment";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import stockTopMenu from '../../components/stockTopMenu.vue'
import {
  saveWeekClassInfo,
  queryWeekClassInfo,
  submitSetClassInfo,
  submitClassByController,
  queryCurrentMonthClass,
  queryGroupClassInfo,
} from "@/libs/http/api.js";
export default {
  name: "ArrangeDrawer",
  components: {
    FullCalendar,
    stockTopMenu
  },
  data() {
    return {
      dialogVisible: false,
      weekVisible: false,
      loading: false,
      fullscreenLoading: false,
      form: {
        submitType: "2",
        perslon: null,
        classId: null,
        weekId: null,
        dateList: "",
        oneTime: "",
        dayEvent: true,
      },
      weekForm: {
        name: "",
        weekDays: 2,
      },
      weekRules: {
        name: [{ required: true, message: "请输入周期名称", trigger: "blur" }],
        weekDays: [
          { required: true, message: "请输入周期天数", trigger: "blur" },
        ],
      },
      perslonList: [],
      weekData: [
        {
          weekId: "",
          id: "1",
        },
        {
          weekId: "",
          id: "2",
        },
      ],
      tableColumn: [
        {
          field: "weekId",
          title: "周期编号",
          minWidth: 150,
        },
        {
          field: "weekName",
          title: "周期名称",
          minWidth: 150,
        },
        {
          title: "操作",
          slots: { default: "operate" },
          fixed: "right",
          minWidth: 150,
        },
      ],
      tableData: [],
      calendarApi: null,
      search: "",
      classInfoList: [],
      month: moment().format("YYYYMM"),
      checkList: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        showNonCurrentDates: false,
        headerToolbar: {
          start: "",
          center: "",
          end: "",
        },
        locale: "zh-cn",
        timeGridEventMinHeight: "40", // 设置事件的最小高度
        aspectRatio: 1, // 设置日历单元格宽度与高度的比例。
        eventLimit: true, // 设置月日程，与all-day slot的最大显示数量，超过的通过弹窗显示
        editable: true, //Event是否可被拖动或者拖拽
        eventStartEditable: true, // Event日程开始时间可以改变，默认true，如果是false其实就是指日程块不能随意拖动，只能上下拉伸改变他的endTime
        eventDurationEditable: true, // Event日程的开始结束时间距离是否可以改变，默认true，如果是false则表示开始结束时间范围不能拉伸，只能拖拽
        dragOpacity: 0.5, //Event被拖动时的不透明度
        events: [], //显示数据
        dateClick: this.handleDateClick, //天点击回调
        eventClick: this.handleEventClick, //事件点击回调
        eventDrop: this.handleEventDrop, //事件拖动回调
        eventResize: this.eventResize, //事件变大回调
        eventChange: this.eventChange,
        setType: "",
        modalParams: null,
      },
    };
  },
  created() {
    this.drawerVisible = true;
  },
  mounted() {
    this.fetchList();
    this.$nextTick(() => {
      this.calendarApi = this.$refs.fullCalendar.getApi();
      // this.fetch();
    });
  },
  watch: {
    month: {
      handler(news, old) {
        this.calendarOptions.validRange = {
          start: moment(news).startOf("month").format("YYYY-MM-DD"),
          end: moment(moment(news).endOf("month"))
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    claer() {
      this.dialogVisible = false;
      this.form.perslon = null;
      this.form.dateList = "";
      this.form.classId = null;
      this.form.weekId = null;
      this.form.oneTime = "";
    },
    newWeekData() {
      let arr = [];
      if (2 <= this.weekForm.weekDays && this.weekForm.weekDays <= 31) {
        for (let i = 0; i < this.weekForm.weekDays; i++) {
          arr.push({
            id: i + 1,
            weekId: "",
          });
        }
      } else {
        return this.$message.error("请输入2-31之间的");
      }
      this.weekData = arr;
    },
    submitForm(formName) {
      let flag = true;
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.weekData.forEach((ele) => {
          if (ele.weekId == "") {
            return (flag = false);
          }
        });
        if (flag) {
          this.saveWeek();
        } else {
          return this.$message.error("请选择班次");
        }
      });
    },
    saveWeek(id) {
      let classId = [];
      const reqData = {
        groupId: this.modalParams.groupId,
      };
      if (id) {
        reqData.id = id;
        reqData.delFlag = "1";
      } else {
        this.weekData.forEach((ele) => {
          classId.push(ele.weekId);
        });
        reqData.name = this.weekForm.name;
        reqData.weekDays = this.weekForm.weekDays;
        reqData.classId = classId.join(",");
      }
      saveWeekClassInfo(reqData)
        .then((data) => {
          this.weekVisible = false;
          this.fetchWeek();
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    fetchWeek() {
      this.loading = true;
      const reqData = {
        groupId: this.modalParams.groupId,
      };
      queryWeekClassInfo(reqData)
        .then((data) => {
          this.loading = false;
          this.tableData = data;
          if (data.length == 0) {
            return;
          }
          this.tableData.forEach((ele) => {
            ele.label = ele.weekName;
            ele.value = ele.weekId;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    saveOneWeek() {
      const reqData = {
        groupId: this.modalParams.groupId,
        submitType: "1",
        weekId: this.form.weekId.value,
        userId: this.form.perslon.value,
        dateList: moment(this.form.oneTime).format("YYYYMMDD"),
      };
      submitSetClassInfo(reqData)
        .then((data) => {
          this.fetch();
          this.form.perslon = null;
          this.form.dateList = "";
          this.form.classId = null;
          this.form.weekId = null;
          this.form.oneTime = "";
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    del(row) {
      this.$confirm("是否删除该周期", {
        type: "warning",
      })
        .then(() => {
          this.saveWeek(row.weekId);
        })
        .catch(() => {});
    },
    submit() {
      //点击当天提交
      let startMonth = moment().format("YYYY-MM-DD");
      let endMonth = moment(this.month).endOf("month").format("YYYY-MM-DD");
      if (!this.form.perslon) {
        return this.$message.error("请选择需要考勤的人员");
      }
      if (this.form.submitType == "1") {
        if (!this.form.oneTime) {
          return this.$message.error("请选择排班开始时间");
        }
        if (!this.form.weekId) {
          return this.$message.error("请选择排班周期");
        }
        let oneFlag = moment(this.form.oneTime).isBetween(
          startMonth,
          endMonth,
          null,
          "[]"
        );
        if (!oneFlag) {
          return this.$message.error("请选择当天到本月之内的时间");
        }
        this.saveOneWeek();
      } else {
        if (!this.form.dateList) {
          return this.$message.error("请选择排班日期");
        }
        if (!this.form.classId) {
          return this.$message.error("请选择排班班次");
        }
        let startFlag = moment(this.form.dateList[0]).isBetween(
          startMonth,
          endMonth,
          null,
          "[]"
        );
        let endFlag = moment(this.form.dateList[1]).isBetween(
          startMonth,
          endMonth,
          null,
          "[]"
        );
        if (!startFlag || !endFlag) {
          return this.$message.error("请选择当天到本月之内的时间");
        }
        let itemList = [
          {
            start: this.form.dateList[0],
            end: this.form.dateList[1],
            classId: this.form.classId.value,
            userId: this.form.perslon.value,
          },
        ];
        this.saveNowMouth(itemList);
      }
      this.dialogVisible = false;
    },
    handleDateClick: function (arg) {
      let startMonth = moment().format("YYYY-MM-DD");
      let endMonth = moment(this.month).endOf("month").format("YYYY-MM-DD");
      let Flag = moment(arg.dateStr).isBetween(
        startMonth,
        endMonth,
        null,
        "[]"
      );
      if (Flag) {
        this.dialogVisible = true;
        this.form.dayEvent = true;
      } else {
        return this.$message.error("请选择今天及本月的最后一天");
      }
    },
    saveNowMouth(itemList) {
      let reqData = {
        groupId: this.modalParams.groupId,
        repairEndTime:'1',
        itemList: itemList,
      };
      submitClassByController(reqData)
        .then((data) => {
          this.fetch();
          this.form.perslon = null;
          this.form.dateList = "";
          this.form.classId = null;
          this.form.weekId = null;
          this.form.oneTime = "";
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    handleEventClick(info) {
      let flag = moment(info.event.endStr).isAfter(
        moment().format("YYYY-MM-DD")
      );
      if (!flag) {
        return this.$message.error(
          "不能修改" + moment().format("MM月DD天") + "之前的时间"
        );
      } else {
        this.form.dayEvent = false;
        this.form.submitType = "2";
        let userName = info.event.title.split(": ")[0];
        let userId = info.event._def.extendedProps.userId;
        let classId = info.event._def.extendedProps.classId;
        let className = info.event.title.split(": ")[1];
        let startTime = info.event.startStr;
        let endTime = moment(info.event.endStr)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.form.perslon = { label: userName, value: userId };
        this.form.classId = { label: className, value: classId };
        this.form.dateList = [startTime, endTime];
        this.dialogVisible = true;
      }
    },
    handleEventDrop(event) {
      this.setType = "0";
    },
    eventResize(event) {
      this.setType = "1";
    },
    fetchList(params) {
      let reqData = {
        page: 1,
        pageSize: 1,
        queryType: "0",
        deptId: [this.$cache.local.get('deptId')],
        queryAssistUser: 1,
      };
      queryGroupClassInfo(reqData)
        .then((data) => {
          this.modalParams = data.items[0];
          if (this.modalParams) {
            this.classInfoList = this.modalParams.classInfoList || [];
            this.classInfoList.forEach((ele) => {
              ele.label = ele.name;
              ele.value = ele.classId;
            });
            this.perslonList = this.modalParams.storeTeamUserList || [];
            this.perslonList.forEach((ele) => {
              ele.label = ele.name;
              ele.value = ele.userId;
            });
          }
          this.fetch();
          this.fetchWeek();
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    fetch() {
      this.fullscreenLoading = true;
      let reqData = {
        groupId: this.modalParams.groupId,
        month: this.month || moment().format("YYYYMM"),
      };
      if (this.search) {
        reqData.userId = this.search;
      }
      let startMonth = moment().format("YYYY-MM-DD");
      let endMonth = moment(this.month).endOf("month").format("YYYY-MM-DD");
      let end = moment(endMonth).add(1, "day").format("YYYY-MM-DD");
      queryCurrentMonthClass(reqData)
        .then((data) => {
          this.calendarOptions.events = data.itemList;
          this.calendarOptions.events.forEach((ele, index) => {
            ele.id = index;
            if (!moment(ele.end).isBetween(startMonth, end, null, "(]")) {
              ele.editable = false;
            }
            if (
              !moment(ele.start).isBetween(startMonth, endMonth, null, "(]")
            ) {
              ele.startEditable = false;
            }
          });
          this.fullscreenLoading = false;
          this.calendarApi.gotoDate(moment(this.month).format("YYYY-MM-DD"));
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
          this.fullscreenLoading = false;
        });
    },
    eventChange(changeInfo) {
      let flag = moment(changeInfo.event.startStr).isSameOrAfter(
        moment().format("YYYY-MM-DD")
      );
      if (!flag) {
        changeInfo.revert();
        return this.$message.error(
          "不能修改" + moment().format("MM月DD天") + "之前的时间"
        );
      } else {
        this.$confirm("此操作将会影响考勤记录，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let itemList = [
              {
                start: changeInfo.event.startStr,
                end: changeInfo.event.endStr,
                classId: changeInfo.event._def.extendedProps.classId,
                userId: changeInfo.event._def.extendedProps.userId,
                deleteStartTime: changeInfo.oldEvent.startStr,
                deleteEndTime: changeInfo.oldEvent.endStr,
                setType: this.setType,
              },
            ];
            this.saveNowMouth(itemList);
          })
          .catch(() => {
            changeInfo.revert();
          });
      }
    },
  },
};
</script>